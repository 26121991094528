import React, { useState } from "react"
import { useWindowWidth } from "@react-hook/window-size"

// Import Local Context(s)
import TogglesContext from "../context/toggles"

const TogglesProvider = ({ children }) => {
  const [togglesOpen, setTogglesOpen] = useState([])
  const windowWidth = useWindowWidth()

  const toggleIndex = index => {
    if (windowWidth < 768) {
      if (togglesOpen.includes(index)) {
        const position = togglesOpen.indexOf(index)
        if (position > -1) {
          const togglesOpenClone = [...togglesOpen]
          togglesOpenClone.splice(position, 1)
          setTogglesOpen(togglesOpenClone)
        }
      } else {
        setTogglesOpen(togglesOpen.concat(index))
      }
    } else {
      if (togglesOpen.includes(index)) {
        setTogglesOpen([])
      } else {
        setTogglesOpen([index])
      }
    }
  }

  return (
    <TogglesContext.Provider value={[togglesOpen, toggleIndex]}>
      {children}
    </TogglesContext.Provider>
  )
}

export default TogglesProvider
