/* 
    This component is used like so: 
        
    <FadeWords copy="">

    It will draw one word at a time 
                
*/

/* Based on: https://github.com/gkaemmer/react-fade-in/blob/master/src/FadeIn.tsx*/

import React, { useState, useEffect } from "react"
import slug from "slug"

const FadeWords = ({
  copy,
  delay = 250,
  transitionDuration = 400,
  style = {},
  className = "",
  start = true,
  callback = () => {},
}) => {
  // copy must be a string, add error check here
  const [isFinished, setIsFinished] = useState(false)
  const [totalWordCount, setTotalWordCount] = useState(0)
  const [currentWordCount, setCurrentWordCount] = useState(0)

  useEffect(() => {
    if (!start) {
      setIsFinished(false)
      setCurrentWordCount(0)
    }
  }, [start])

  useEffect(() => {
    const words = copy.split(" ")

    if (words.length > 0) {
      setTotalWordCount(words.length)
    }
  }, [copy])

  // track total word count
  useEffect(() => {
    if (!start) {
      return
    }
    // If the current word count is the same as the total word count, stop incrementing
    if (currentWordCount === totalWordCount && totalWordCount > 0) {
      setIsFinished(true)
    }

    // move currentWordCount towards totalWordCount
    const increment = 1
    const timeout = setTimeout(() => {
      setCurrentWordCount(currentWordCount + increment)
    }, delay)
    return () => clearTimeout(timeout)
  }, [currentWordCount, totalWordCount, delay, start, setIsFinished])

  useEffect(() => {
    if (isFinished && callback) {
      callback()
    }
  }, [isFinished, callback])

  return (
    <p aria-label={copy} style={style} className={className}>
      {copy.split(" ").map((word, index) => {
        return (
          <span
            key={slug(`${word}-${index}`)}
            style={{
              transition: `opacity ${transitionDuration}ms`,
              opacity: currentWordCount > index ? 1 : 0,
            }}
          >
            {`${word} `}
          </span>
        )
      })}
    </p>
  )
}

export default FadeWords
