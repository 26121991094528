import React, { useState } from "react"
import ReactPlayer from "react-player"
import classnames from "classnames"

/* Import Local Styles */
import "./background-video.css"

const BackgroundVideo = ({ playbackId }) => {
  const [isReady, setIsReady] = useState(false)
  return (
    <div className={classnames("background-video", { "is-ready": isReady })}>
      <ReactPlayer
        className="react-player"
        controls={false}
        width="100%"
        height="100%"
        autoPlay
        loop={true}
        volume={0}
        muted={true}
        playing={true}
        playsinline={true}
        onReady={() => {
          setIsReady(true)
        }}
        pip={false}
        url={`https://stream.mux.com/${playbackId}.m3u8`}
      />
    </div>
  )
}
export default BackgroundVideo
