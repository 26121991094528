import React, { useContext } from "react"
import classnames from "classnames"

/* Import Local Context */
import TogglesContext from "../../context/toggles"

/* Import Local Styles */
import "./toggle.css"

const Toggle = ({ children, header, index, style = {} }) => {
  const [togglesOpen, toggleIndex] = useContext(TogglesContext)

  return (
    <div className="toggle" style={style}>
      <button
        className="toggle-header"
        onClick={() => {
          toggleIndex(index)
        }}
      >
        {header}
      </button>
      <div
        className={classnames("toggle-content", {
          open: togglesOpen.includes(index),
        })}
      >
        {children}
      </div>
    </div>
  )
}

export default Toggle
