import React from "react"

/* Import Local Providers */
import TogglesProvider from "./providers/toggles"

const Toggles = ({ children }) => {
  return (
    <TogglesProvider>
      <div className="toggles">{children}</div>
    </TogglesProvider>
  )
}

export default Toggles
