import React, { useState, useContext } from "react"
import { useEffectOnce } from "react-use"
import { Link, graphql } from "gatsby"
import slug from "slug"
import BlockContent from "@sanity/block-content-to-react"

/* Import Global Components */
import Page from "~components/page"
import FadeIn from "~components/animations/fadeInChildElements"
import FadeInWords from "~components/animations/fadeInWordsSequentially"
import Toggles from "~components/toggles"
import Toggle from "~components/toggles/components/toggle"
import RightPointer from "~components/icon/normal/rightPointer"

/* Import Global Context */
import ColorContext from "~context/color"

/* Import Local Components */
import BackgroundVideo from "./components/backgroundVideo"

/* Import Local Styles */
import "./about.css"

const About = ({ data: { sanityAboutSettings: about } }) => {
  const [color, setColor] = useContext(ColorContext)
  const [
    heroTextAnimationIsFinished,
    setHeroTextAnimationIsFinished,
  ] = useState(false)
  const [
    linksTextAnimationsIsFinished,
    setLinksTextAnimationsIsFinished,
  ] = useState(false)

  // the above page is always white, reset on page load and unload
  useEffectOnce(() => {
    const initialColors = {
      text: color.text,
      background: color.background,
      newMenuColor: color.newMenuColor,
      newMenuBackgroundColor: color.newMenuBackgroundColor,
    }
    setColor({
      text: "white",
      background: "black",
      newMenuColor: "purple",
      newMenuBackgroundColor: "black",
    })
    return () => {
      setColor(initialColors)
    }
  }, [])

  return (
    <Page className="about">
      <div className="container">
        <div className="top-gradient" />
        <BackgroundVideo playbackId={about.backgroundVideo.asset.playbackId} />
        <div className="about-description">
          <FadeIn>
            <FadeInWords
              copy={about.description}
              delay={250}
              transitionDuration={400}
              callback={() => {
                setHeroTextAnimationIsFinished(true)
              }}
            />
            <div className="links">
              <FadeIn
                delay={325}
                transitionDuration={520}
                start={heroTextAnimationIsFinished}
                callback={() => {
                  setLinksTextAnimationsIsFinished(true)
                }}
              >
                <Link to="/works">
                  Explore Work{" "}
                  <span>
                    <RightPointer />
                  </span>
                </Link>
                <Link to="/contact">
                  Get in Touch{" "}
                  <span>
                    <RightPointer />
                  </span>
                </Link>
              </FadeIn>
            </div>
          </FadeIn>
        </div>
        <Toggles>
          <FadeIn
            start={linksTextAnimationsIsFinished}
            delay={325}
            transitionDuration={520}
          >
            {about.aboutToggles.map((toggle, index) => (
              <Toggle
                index={index}
                key={slug(toggle.title)}
                header={
                  <>
                    <p>{`0${index + 1}`}</p>
                    <p>{toggle.title}</p>
                  </>
                }
              >
                <BlockContent blocks={toggle._rawCopy} />
              </Toggle>
            ))}
          </FadeIn>
        </Toggles>
        <div className="bottom-gradient" />
      </div>
    </Page>
  )
}

export default About

export const query = graphql`
  query AboutPageQuery {
    sanityAboutSettings(_id: { eq: "aboutSettings" }) {
      id
      description
      aboutToggles {
        _rawCopy
        title
      }
      backgroundVideo {
        asset {
          playbackId
        }
      }
    }
  }
`
